.wallet-container {
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;

  // Espaço entre seções (utilizando Divider do antd, mas adicionando margem extra se necessário)
  > :not(:last-child) {
    margin-bottom: 24px;
  }

  .cards-section {
    h3 {
      font-size: 20px;
      color: #333;
      margin-bottom: 16px;
      font-weight: 500;
    }

    // Estilização dos cartões (ant-card)
    .ant-card {
      border: 1px solid #e8e8e8;
      border-radius: 6px;
      transition: box-shadow 0.3s ease;

      &:hover {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }

      .ant-card-head {
        background-color: #f9f9f9;
        border-bottom: 1px solid #e8e8e8;
        font-weight: 600;
      }

      .ant-card-body {
        font-size: 16px;
        color: #555;
      }
    }
  }

  .subscriptions-section {
    h3 {
      font-size: 20px;
      color: #333;
      margin-bottom: 16px;
      font-weight: 500;
    }

    // Ajusta a aparência das tabs do antd
    .ant-tabs {
      .ant-tabs-nav {
        margin-bottom: 12px;
      }
    }

    // Ajuste dos itens da lista de assinaturas
    .ant-list-item {
      border-bottom: 1px solid #e8e8e8;
      padding: 12px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background-color: #f5f5f5;
      }
    }

    // Estilização para o botão "Renovar"
    .ant-btn-link {
      color: #1890ff;
      transition: color 0.3s ease;

      &:hover {
        color: #40a9ff;
      }
    }
  }
}
